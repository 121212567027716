div.page-break {margin-top: 50px;}

div.cookies-body {
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 999999;
	padding: 10px;
	width: 100%;
	font-size: 12px;
	color: #FFF;
	line-height: 12px;
	background: rgba(0,0,0,0.7);
}

div.cookies-body a {text-decoration: underline; color: #CCC;}
div.cookies-body a.close-btn {
	float: right;
	display: block;
	padding: 10px 15px;
	margin: -10px;
	background: #EEE;
	color: #333;
	line-height: 12px;
}

div.cookies-body a.close-btn:hover {color: #EEE; background: #333;}

div.error_404 {text-align: center;}
div.error_404 i {font-size: 100px; color: #E99C11;}

.angular-google-map-container { height: 400px; }
.text-center {text-align: center;}

/*
	Comments
*/
ul.commentsList li.comment div.replay {visibility: hidden; height: 0; opacity: 0; -webkit-transition: all .3s ease; transition: all .3s ease;}
ul.commentsList li.comment:hover > div.replay {visibility: visible; height: 30px; opacity: 1;}

input#url, input#antispam {display: none;} /* Antispam */
